// extracted by mini-css-extract-plugin
export var column = "LocationBrochuresIndexSidebar__column__ey4uY";
export var flex = "LocationBrochuresIndexSidebar__flex__DK2Yb";
export var flexColumn = "LocationBrochuresIndexSidebar__flexColumn__xAiua";
export var gap1 = "LocationBrochuresIndexSidebar__gap1__km_zp";
export var gap2 = "LocationBrochuresIndexSidebar__gap2__Wdwlg";
export var gap3 = "LocationBrochuresIndexSidebar__gap3__zSiCX";
export var gap4 = "LocationBrochuresIndexSidebar__gap4__aEBpQ";
export var gap5 = "LocationBrochuresIndexSidebar__gap5__oY7v7";
export var locationBrochureLink = "LocationBrochuresIndexSidebar__locationBrochureLink__nUPsf";
export var popularBrochures = "LocationBrochuresIndexSidebar__popularBrochures__DV5Pu";
export var recentBrochures = "LocationBrochuresIndexSidebar__recentBrochures__ZbVhC";
export var row = "LocationBrochuresIndexSidebar__row__yJpGk";
export var sidebar = "LocationBrochuresIndexSidebar__sidebar__iuhjS";