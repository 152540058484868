// extracted by mini-css-extract-plugin
export var column = "LocationBrochuresIndex__column__pGn8l";
export var container = "LocationBrochuresIndex__container__kLqzh";
export var contentContainer = "LocationBrochuresIndex__contentContainer__lunOe";
export var flex = "LocationBrochuresIndex__flex__SLV_q";
export var flexColumn = "LocationBrochuresIndex__flexColumn__Pmsdm";
export var gap1 = "LocationBrochuresIndex__gap1__k2aai";
export var gap2 = "LocationBrochuresIndex__gap2__tEXO8";
export var gap3 = "LocationBrochuresIndex__gap3__q0U5D";
export var gap4 = "LocationBrochuresIndex__gap4__TpWnx";
export var gap5 = "LocationBrochuresIndex__gap5__Z_EQT";
export var locationBrochuresContainer = "LocationBrochuresIndex__locationBrochuresContainer__w9aCD";
export var paginationBar = "LocationBrochuresIndex__paginationBar__MNg0y";
export var paginationInfo = "LocationBrochuresIndex__paginationInfo__rI3vA";
export var popoverBody = "LocationBrochuresIndex__popoverBody__tHfwI";
export var popoverLabel = "LocationBrochuresIndex__popoverLabel__Y7HqD";
export var popoverRow = "LocationBrochuresIndex__popoverRow__dEqAq";
export var popoverTrigger = "LocationBrochuresIndex__popoverTrigger__piDUU";
export var row = "LocationBrochuresIndex__row__Z0eXA";
export var sidebarContainer = "LocationBrochuresIndex__sidebarContainer__QfzFK";
export var sortSelection = "LocationBrochuresIndex__sortSelection__Pv74c";